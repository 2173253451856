;
/**
 * Application custom script
 * @param object jQuery $
 */

(function ($) {
    'use strict';

    /**
     * Search toolbar
     */
    $('#search-btn').click(function () {
        var $search_widget = $('#search_widget');

        if ($search_widget.is(':hidden')) {
            $search_widget.stop(true).slideDown('fast');
        } else {
            $search_widget.stop(true).slideUp('fast');
        }
    });

    /**
     * Home site carousel
     */
    $('#carouselHome').slick({
        dots: true,
        cssEase: 'ease-out',
        arrows: false,
        speed: 200
    });

    /**
     * Carousel global
     */
    $('.slick-slide').click(function () {
        location.href = $(this).find('a').attr('href');
    });

    /**
     * Link block with data-action="link
     * 
     */
    if ($('*[data-action="link"]').length) {
        $('*[data-action="link"]').click(function () {
            location.href = $(this).find('a').attr('href');
        });
    }


    /**
     * Menu fixed position
     * 
     */
    var MENU_FIXED = (function ($, imenu) {
        'use strict';
        var $menu_contener = $('#header-contener'),
            $logo = $('#_desktop_logo img,#_mobile_logo img'),
            menu_contener_offset,
            offset_resize = '100';

        /**
         * Resize logo after offset
         */
        function resizeLogo(offset) {
            if (offset > offset_resize) {
                $menu_contener.addClass('low');

            } else {
                $menu_contener.removeClass('low');
            }
        }
        /**
         * Set body offset
         */
        function setBodyOffset() {
            setTimeout(function () {
                $('body').stop(1).css('marginTop', $menu_contener.height());
            }, 500);
        }

        /**
         * Constructor
         * 
         */
        imenu.init = function () {
            console.log('st');
            resizeLogo(menu_contener_offset);
            setBodyOffset();
            $(document).scroll(function () {
                menu_contener_offset = parseInt($menu_contener.offset().top, 10);
                resizeLogo(menu_contener_offset);
            });
            $(window).resize(function () {
                setBodyOffset();
            });

        }
        ;
        /**
         * Return interface
         */
        return imenu;
    }(jQuery, MENU_FIXED || {}));

    MENU_FIXED.init();


})(jQuery);
